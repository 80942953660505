import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: center;
  background-color: var(--darkLight);
  position: relative;
  overflow: hidden;
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 2rem;
  display: flex;
  min-height: 100vh;
  padding: 10rem 2rem;
  flex-direction: column;
  z-index: 1;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 4rem;
  margin-bottom: 4rem;

  @media screen and (max-width: 728px) {
    font-size: 3rem;
  }
`;

export const Description = styled.span`
  text-align: center;
  font-size: 1.5rem;
  line-height: 3rem;

  @media screen and (max-width: 728px) {
    font-size: 1.1rem;
  }
`;

export const Background = styled.div`
  background: var(--dark);
  display: flex;
  position: absolute;
  top: 5rem;
  bottom: 5rem;
  right: -50rem;
  left: -50rem;
  transform: rotate(-2deg);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
`;
