import React, { type ButtonHTMLAttributes, type ReactNode } from 'react';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  icon: ReactNode;
  site: 'Buddy' | 'SolusChat';
}

export function Button({ children, icon, site, ...rest }: ButtonProps) {
  return (
    <Container {...rest} site={site}>
      {children}
      {icon}
    </Container>
  );
}
