import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';

import { FaWhatsapp } from 'react-icons/fa6';
import { BsFillArrowUpSquareFill } from 'react-icons/bs';

import { Container } from './styles';

interface Props {
  site: 'Buddy' | 'SolusChat';
}

export function Bottom({ site }: Props) {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Container scrolling={scrolling} site={site}>
        <a href="https://api.whatsapp.com/send?phone=5548991616929&text=Ol%C3%A1,%20me%20interessei%20por%20um%20produto%20da%20Buddy%20Software!%20" target="_blank" rel="noreferrer">
          <FaWhatsapp className="whatsapp" />
        </a>
      <Link smooth spy to={site === 'Buddy' ? 'home' : site === 'SolusChat' ? 'homesoluschat' : ''}>
        <BsFillArrowUpSquareFill className="toTop" />
      </Link>
    </Container>
  );
}
