import React, { useEffect } from 'react';

import { Header } from '../../components/Header';
import { Home } from '../../components/sections/Home';
import { Faq } from '../../components/sections/Faq';
import { Bottom } from '../../components/Bottom';
import { Services } from '../../components/sections/Services';
import { About } from '../../components/sections/About';
import { Footer } from '../../components/Footer';
import { Products } from '../../components/sections/Products';

import { Container } from './styles';

export function LandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  },[]);

  return (
    <Container>
      <Header />
      <Home />
      <Services />
      <About />
      <Products />
      <Faq />
      <Footer />
      <Bottom site='Buddy'/>
    </Container>
  );
}
