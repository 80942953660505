import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  text-align: center;
  background: var(--dark);
  border-radius: 0.5rem;
  overflow: hidden;
  color: var(--light);
  width: 40rem;

  @media screen and (max-width: 600px) {
    width: 20rem;
  }
`;

export const ImgContainer = styled.div`
  overflow: hidden;
  height: 30em;
  cursor: zoom-in;

  img {
    width: 100%;
    pointer-events: none;
  }

  @media screen and (max-width: 600px) {
    height: 18rem;
  }
`;

export const Title = styled.h2`
  padding: 1rem;
  color: var(--primary);
  filter: brightness(1.5);
`;

export const Description = styled.span`
  display: flex;
  font-size: 0.9rem;
  line-height: 1.2rem;
  padding: 0 1rem 1rem 1rem;
  height: 9rem;
  text-align: center;
  align-items: center;
`;
