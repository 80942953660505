import styled from 'styled-components';

interface FAQProps {
  open: boolean;
  site?: 'buddy' | 'SolusChat';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  border-bottom: 1px solid var(--light);
`;

export const TitleContainer = styled.div`
  display: flex;
`;

export const Title = styled.h1<FAQProps>`
  cursor: pointer;
  font-size: 1.1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: color 0.5s ease;
  color: ${({ open, site }) =>
    open && site === 'buddy'
      ? 'var(--primary)'
      : open && site === 'SolusChat'
        ? 'var(--primarySolusChat)'
        : 'var(--light)'};
  filter: ${({ open, site }) => open && site === 'buddy' && 'brightness(1.5)'};
  padding: 1rem 0;

  svg {
    transition: transform 0.5s ease;
    font-size: 1.5rem;
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

export const Description = styled.div<FAQProps>`
  font-size: 0.9rem;
  transition: max-height 0.5s ease;
  max-height: ${({ open }) => (open ? '20rem' : '0rem')};
  overflow: hidden;
  line-height: 1.2rem;
  white-space: pre-wrap;
`;
