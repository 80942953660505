import { createGlobalStyle } from 'styled-components';

export const DarkTheme = {
  primary: '#8b00bf',
  primaryLight: '#B5F9C8',
  secondary: '#0A293B',
  dark: '#1a1a1a',
  darkLight: '#1f1f1f',
  light: '#fafcfb',

  primarySolusChat: '#68f48f',
  primaryLightSolusChat: '#B5F9C8',
  secondarySolusChat: '#0A293B',
  darkSolusChat: '#1a1a1a',
  darkLightSolusChat: '#1f1f1f',
  lightSolusChat: '#fafcfb',
};

export const LightTheme = {};

export const GlobalStyles = createGlobalStyle`
    :root {
        --primary: ${props => props.theme.primary};
        --primaryLight: ${props => props.theme.primaryLight};
        --secondary:  ${props => props.theme.secondary};
        --dark:  ${props => props.theme.dark};
        --darkLight:  ${props => props.theme.darkLight};
        --light:  ${props => props.theme.light};

        --primarySolusChat: ${props => props.theme.primarySolusChat};
        --primaryLightSolusChat: ${props => props.theme.primaryLightSolusChat};
        --secondarySolusChat:  ${props => props.theme.secondarySolusChat};
        --darkSolusChat:  ${props => props.theme.darkSolusChat};
        --darkLightSolusChat:  ${props => props.theme.darkLightSolusChat};
        --lightSolusChat:  ${props => props.theme.lightSolusChat};
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    html {
      @media (max-width: 1080px) {
        font-size: 93.75%;
      }

      @media (max-width: 728px) {
        font-size: 87.5%;
      }
    }


    body {
        background: var(--dark);
        color: var(--light);
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
    }

    body, input, button {
        font-family: 'Comfortaa', serif;
        font-size: 16px;
    }

    h1, h2, h3, h4, h5, h6, string {
        font-weight: 600;
    }

    h1 {
      font-size: 2.8rem;
    }

    span {
      font-size: 1.2rem;
      line-height: 2rem;
    }

    button {
        cursor: pointer;
    }

    /* ::-webkit-scrollbar {
      width: 0.6rem !important;
    }

    ::-webkit-scrollbar-track {
      background: var(--dark) !important;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--primary) !important;
      border-radius: 1rem;
      border: 1px solid var(--dark) !important;

      :hover {
        border: 1px solid var(--primary) !important;
      }
    } */
`;
