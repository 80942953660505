import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.section``;

export const Content = styled(motion.div)`
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;

  flex-wrap: wrap;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  min-width: 32rem;

  h1 {
    padding: 2rem 0;

    @media screen and (max-width: 1000px) {
      padding-top: 8rem;
    }
  }

  span {
    span {
      color: var(--primary);
    }
  }

  button {
    align-self: baseline;
    margin-top: 2rem;
  }

  @media screen and (max-width: 1000px) {
    min-width: 0;
  }

  @media screen and (max-width: 1230px) {
    text-align: center;
    align-items: center;
  }
`;

export const RightSide = styled.div`
  display: flex;
  padding-left: 5.5rem;
  position: relative;

  .people {
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1));
    z-index: 1;
    height: 30rem;
    transform: rotateY(180deg);
  }

  @media screen and (max-width: 728px) {
    .people {
      width: 100%;
      height: 100%;
    }
}

  @media screen and (max-width: 1134px) {
    padding: 3rem 0;
  }
`;
