import React from 'react';
import { MdEmail } from 'react-icons/md';
import { IoLogoWhatsapp } from 'react-icons/io';

import { frequencyQuestions } from '../../../utils/faq';
import { FaqContainer } from '../../FaqContainer';

import { Container, Content, Title, LeftSide, RightSide, Subtitle, Buttons } from './styles';
import { Button } from '../../Button';

import { FaqData } from '../../../utils/texts';

export function Faq() {
  return (
    <Container id='help'>
      <Content>
        <LeftSide>
          <Title>{FaqData.title}</Title>
          <Subtitle>{FaqData.description}</Subtitle>

          <Buttons>
            <Button icon={<MdEmail />} site='Buddy' onClick={() => window.open('mailto:comercial@buddysoftware.com.br?subject=&body=')}>E-mail</Button>
            <a href="https://api.whatsapp.com/send?phone=554899142-3301&text=Ol%C3%A1,%20tenho%20uma%20dúvida%20sobre%20a%20Buddy%20Software!%20" target="_blank" rel="noreferrer">
              <Button icon={<IoLogoWhatsapp />} site='Buddy'>Whatsapp</Button>
            </a>
          </Buttons>
        </LeftSide>
        <RightSide>
          {frequencyQuestions.map((question) => <FaqContainer key={question.id} title={question.title} description={question.description} site='buddy' />)}
        </RightSide>
      </Content>
    </Container>
  );
}
