import React, { useEffect, useState } from 'react';

import {
  Container,
  Content,
  Title,
  Description,
  ProductsContainer,
  CardTabs,
  ProductName,
  Details,
  ImgSoonContainer,
  TotalItens,
  ItensButton,
  Modal,
} from './styles'

import { CarouselContainer } from '../../CarouselContainer';
import { products } from '../../../utils/products';
import { Link } from 'react-router-dom';
import SoonImg from '../../../assets/soon.svg';

import { ProductsData } from '../../../utils/texts';

export function Products() {
  const [productToShow, setProductToShow] = useState(products[0].product);
  const [itensToShow, setItensToShow] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showModal]);

  return (
    <Container id='products' >
      <Title>
        {ProductsData.title}
      </Title>
      <ProductsContainer>
        <CardTabs>
          {products.map((product) => (
            <React.Fragment key={product.id}>
              <ProductName
                onClick={() => { setProductToShow(product.product); setItensToShow(0); }}
                productToShow={productToShow}
                product={product.product}
              >
                {product.product}
              </ProductName>
            </React.Fragment>
          ))}
        </CardTabs>
        <Details>
          {products.filter(product => product.product === productToShow).map((product) =>
            <React.Fragment key={product.id}>
              <Description>{product.description}</Description>
              <Link to={product.link}>Site {product.product}</Link>
            </React.Fragment>
          )}
        </Details>
        <Content>
          {products.filter(product => product.product === productToShow).map((product) =>
            product.items && product.items.length > 0 ? (
              <CarouselContainer
                key={product.items[itensToShow].id}
                img={product.items[itensToShow].img}
                title={product.items[itensToShow].title}
                description={product.items[itensToShow].description}
                zoomIn={() => { setShowModal(true); }}
              />
            ) : <ImgSoonContainer key={0} src={SoonImg} alt='ImgSoon' />
          )}
          <TotalItens>
            {products.filter(product => product.product === productToShow).map((product) =>
              product.items && product.items.length > 0 && (
                product.items.map((item, index) => (
                  <ItensButton
                    key={item.id}
                    className={index === itensToShow ? 'actived' : ''}
                    onClick={() => { setItensToShow(index); }}
                  />
                ))
              )
            )}
          </TotalItens>
        </Content>
      </ProductsContainer>

      {showModal &&
        <Modal>
          <h2 onClick={() => { setShowModal(false); }}>X</h2>
          {products.filter(product => product.product === productToShow).map((product) =>
            <React.Fragment key={product.items?.[itensToShow]?.id}>
              <h1>{product.items?.[itensToShow]?.title}</h1>
              <span>{product.items?.[itensToShow]?.description} </span>
              <img src={product.items?.[itensToShow]?.img} alt={product.items?.[itensToShow]?.title} key={product.items?.[itensToShow]?.id} />
            </React.Fragment>
          )}
          <TotalItens>
            {products.filter(product => product.product === productToShow).map((product) =>
              product.items && product.items.length > 0 && (
                product.items.map((item, index) => (
                  <ItensButton
                    key={item.id}
                    className={index === itensToShow ? 'actived' : ''}
                    onClick={() => { setItensToShow(index); }}
                  />
                ))
              )
            )}
          </TotalItens>
        </Modal>
      }
    </Container>
  );
}