import React from 'react';

import Logo from '../../assets/logo.png';

import { Container } from './styles';
import { DarkTheme } from '../../styles/global';
import { PuffLoader } from 'react-spinners';

export function LoadingPage() {
  return (
    <Container>
      <img src={Logo} alt="Logo" />

      <PuffLoader color={DarkTheme.primary} />
    </Container>
  );
}
