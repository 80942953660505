import React, { useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";

import {
  Container,
  TitleContainer,
  Title,
  Description
} from './styles'

interface FaqContainerProps {
  title: string;
  description: string;
  site: 'buddy' | 'SolusChat'
}

export function FaqContainer({ title, description, site }:FaqContainerProps){
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <TitleContainer onClick={() => { setOpen(!open); }}>
        <Title open={open} site={site}>{title}<IoIosArrowDown /></Title>
      </TitleContainer>
      <Description open={open}>{description}</Description>
    </Container>
  );
}
