import styled from 'styled-components';
import { motion } from 'framer-motion';

interface HoverProps {
  hover: boolean;
}

export const Container = styled(motion.div)<HoverProps>`
  transition: background 0.5s ease;
  background: ${({ hover }) => (hover ? 'var(--dark)' : 'var(--primary)')};
  color: var(--light);
  border-radius: 0.8rem;
  max-width: 40rem;
  min-height: 20rem;
  display: flex;
  padding: 1rem;

  @media screen and (max-width: 728px) {
    flex-direction: column-reverse;
  }
`;

export const LeftSide = styled.div<HoverProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  svg {
    align-self: center;
    font-size: ${({ hover }) => (hover ? '0rem' : '1.2rem')};
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex: 1;
  max-width: 15rem;

  img {
    width: 100%;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  }

  @media screen and (max-width: 728px) {
    align-self: center;
    height: 60%;
  }
`;

export const Title = styled.h2`
  border-left: 2px solid var(--light);
  padding: 0.5rem 1rem;
`;

export const Description = styled.span<HoverProps>`
  font-size: 0.9rem;
  overflow: hidden;
  line-height: 1.2rem;
  margin-top: 0.5rem;
  transition: max-height 0.5s ease;
  max-height: ${({ hover }) => (hover ? '11rem' : '0rem')};
  padding: 0 1rem;
`;
