import styled from 'styled-components';

interface ButtonProps {
  site: 'Buddy' | 'SolusChat';
}

export const Container = styled.button<ButtonProps>`
  display: flex;
  min-width: 10rem;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border: none;
  border-radius: 0.25rem;
  font-weight: bold;

  background: ${({ site }) =>
    site === 'Buddy'
      ? 'var(--primary)'
      : site === 'SolusChat'
        ? 'var(--primarySolusChat)'
        : ''};
  color: ${({ site }) =>
    site === 'Buddy'
      ? 'var(--light)'
      : site === 'SolusChat'
        ? 'var(--darkSolusChat)'
        : ''};

  svg {
    font-size: 1.2rem;
  }
`;
