import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import { LandingPage } from '../pages/LandingPage';
import { Page404 } from '../pages/Page404';
import TermsOfUse from '../pages/terms-of-use.js'; 
import PrivacyPolicy from '../pages/privacy-policy.js'; 

export function RouterPage() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}
