interface HomeProps {
  title: string;
  description: string;
  buttonText: string;
}

interface ServicesProps {
  title: string;
  description: string;
}

interface AboutProps {
  title: string;
  description: string;
}

interface ProductsProps {
  title: string;
}

interface FaqProps {
  title: string;
  description: string;
}

export const HomeData: HomeProps = {
  title: `Sua Parceria <br /> em Soluções Digitais`,
  description: `Bem-vindo à <span>Buddy Software</span>! Somos
  especialistas em desenvolver soluções digitais sob medida para o
  sucesso do seu negócio. Nossa equipe apaixonada por tecnologia está
  pronta para transformar suas ideias em realidade, incluindo
  integrações inteligentes para otimizar seus processos.`,
  buttonText: 'Contato'
};

export const ServicesData: ServicesProps = {
  title: 'Nossos serviços',
  description: 'Oferecemos uma ampla gama de serviços para atender às suas  necessidades específicas'
};

export const AboutData: AboutProps = {
  title: 'Sobre a Buddy',
  description: 'Na Buddy Software, acreditamos que a inovação é a chave para o crescimento. Nossa equipe é composta por profissionais talentosos e experientes, formando um grupo diversificado de desenvolvedores, designers e especialistas em tecnologia. Trabalhamos em estreita colaboração com nossos clientes para entender suas necessidades e objetivos, garantindo que cada projeto seja único e eficiente.'
};

export const ProductsData: ProductsProps = {
  title: 'Produtos',};

export const FaqData: FaqProps = {
  title: 'Dúvidas frequentes',
  description: 'Caso não encontre sua dúvida aqui, entre em contato com o nosso suporte.'
};
