import styled from 'styled-components';

export const Container = styled.div`
  background: var(--dark);
`;

export const Content = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 5rem 2rem;
  max-width: 1400px;
  min-height: 60.2vh;

  @media screen and (max-width: 728px) {
    flex-direction: column;
    padding: 8rem 2rem;
  }
`;

export const Title = styled.h1``;

export const LeftSide = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 3.5rem;
  padding-right: 1rem;

  @media screen and (max-width: 728px) {
    width: 100%;
    text-align: center;
    padding-top: 0;
  }
`;

export const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Subtitle = styled.span`
  font-size: 1rem;
  padding: 1rem 0;
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  a {
    text-decoration: none;
  }
`;
