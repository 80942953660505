import styled from 'styled-components';

interface ProductProps {
  productToShow: string;
  product: string;
}

export const Container = styled.section`
  background: var(--darkLight);
  padding: 8rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  min-height: 100vh;
`;

export const Content = styled.div`
  margin: 0 auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  display: flex;
  max-width: 1400px;
  text-align: center;
  white-space: nowrap;
`;

export const Description = styled.span`
  display: flex;
  max-width: 1400px;
  font-size: 1rem;
  text-align: center;
  padding: 0 2rem;
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  flex-wrap: wrap;
`;

export const ProductName = styled.span<ProductProps>`
  cursor: pointer;
  width: 12rem;
  background: ${({ productToShow, product }) =>
    product === productToShow ? 'var(--primary)' : 'var(--dark)'};
  color: var(--light);
  text-align: center;
  padding: 1rem;

  &:first-of-type {
    border-radius: 0.5rem 0 0 0.5rem;
  }
  &:last-of-type {
    border-radius: 0 0.5rem 0.5rem 0;
  }
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;

  a {
    text-decoration: none;
    color: var(--primary);
    font-weight: bold;
    font-size: 1.2rem;
    filter: brightness(1.5);
  }
`;

export const ImgSoonContainer = styled.img`
  width: 31rem;
  margin-top: -1.4rem;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const TotalItens = styled.div`
  display: flex;
  width: fit-content;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem;
  flex-wrap: wrap;
  margin: 0 auto;
`;

export const ItensButton = styled.div`
  background: var(--light);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  cursor: pointer;

  &.actived {
    background: var(--primary);
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  z-index: 1000;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.97);
    z-index: -1;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    position: absolute;
    right: 2rem;
    top: 2rem;
    cursor: pointer;
  }

  img {
    width: 80%;
    max-width: 60rem;
  }

  span {
    font-size: 1rem;
    padding: 1rem 0;
    text-align: center;
  }
`;
