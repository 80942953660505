import agendamentos from '../assets/solusChat/agendamentos.jpg';
import api from '../assets/solusChat/api.jpg';
import atendimentos from '../assets/solusChat/atendimentos.jpg';
import campanhas from '../assets/solusChat/campanhas.jpg';
import chatInterno from '../assets/solusChat/chatInterno.jpg';
import conexoes from '../assets/solusChat/conexoes.jpg';
import configuracoes from '../assets/solusChat/configuracoes.jpg';
import dashboard from '../assets/solusChat/dashboard.jpg';
import etiquetas from '../assets/solusChat/etiquetas.jpg';
import faturas from '../assets/solusChat/faturas.jpg';
import respostaRapidas from '../assets/solusChat/respostaRapidas.jpg';
import setoresSolusChat from '../assets/solusChat/setoresSolusChat.jpg';
import todo from '../assets/solusChat/todo.jpg';
import usuarios from '../assets/solusChat/usuarios.jpg';

interface ProductsProps {
  id: number;
  product: string;
  description: string;
  link: string;
  items?: Array<{
    id: number;
    title: string;
    description: string;
    img: string;
  }>;
}

export const products: ProductsProps[] = [
  {
    id: 1,
    product: 'SolusChat',
    description:
      'O SolusChat é um sistema de gerenciamento de atendimento para Whatsapp e com ele você economiza em celulares e facilita sua gestão. Centenas de atendentes centralizados em apenas um número, atendimento automatizado com inteligência artificial',
    link: 'http://www.soluschat.com.br/',
    items: [
      {
        id: 1,
        title: 'Dashboard',
        description: `Nesta aba, você pode visualizar com gráficos e totalizadores, algumas informações gerais sobre as métricas do sistema, como os atendimentos realizados, tempo médio de espera para atendimento, e por assim vai.`,
        img: dashboard,
      },
      {
        id: 2,
        title: 'Atendimentos',
        description: `Por aqui é possível visualizar e gerenciar, de forma intuitiva, todos os atendimentos que estão pendentes ou em andamento. Você pode buscar atendimentos, gerar um novo manualmente, editar tags para facilitar em alguma busca posterior, e muito mais.`,
        img: atendimentos,
      },
      {
        id: 3,
        title: 'ToDoList',
        description: `Esta aba foi feita para ajudar o seu atendente a organizar e lembrar de tarefas que podem se acumular no dia-a-dia. Lembrando que cada atendente possui uma conta separada e essas informações não são compartilhadas.`,
        img: todo,
      },
      {
        id: 4,
        title: 'Respostas Rápidas',
        description: `É por aqui que seu atendente pode configurar comandos para agilizar o processo de atendimento. Como, por exemplo, configurar um atalho para listar uma grande quantidade de informações e valores.`,
        img: respostaRapidas,
      },
      {
        id: 5,
        title: 'Agendamentos',
        description: `É nesta aba que você pode agendar, de forma recorrente ou não, mensagens para algum cliente específico, e visualizar o status desses agendamentos.`,
        img: agendamentos,
      },
      {
        id: 6,
        title: 'Etiquetas',
        description: `As etiquetas são tags que podem ser utilizadas para agrupar e diferenciar atendimentos, são configuradas aqui mas são adicionadas diretamente nos atendimentos.`,
        img: etiquetas,
      },
      {
        id: 7,
        title: 'Chat Interno',
        description: `Nossa aplicação possui um sistema de chat interno que pode ser utilizado para se comunicar diretamente com outros colaboradores, desde que o mesmo faça parte da sua instituição.`,
        img: chatInterno,
      },
      {
        id: 8,
        title: 'Campanhas',
        description: `Campanhas são mensagens agendadas, porém para diversos contatos simultaneamente.`,
        img: campanhas,
      },
      {
        id: 9,
        title: 'Conexões',
        description: `É por aqui que você gerencia as suas conexões com o WhatsApp, nomeando-as para fazer uma fácil identificação.`,
        img: conexoes,
      },
      {
        id: 10,
        title: 'Setores e Chatbot',
        description: `Esta aba permite que você adicione e configure todos os setores de atendimento. É por aqui também que é possível configurar mensagens de saudação, horários de atendimento de acordo com cada fila.`,
        img: setoresSolusChat,
      },
      {
        id: 11,
        title: 'Usuários',
        description: `Na aba de usuários é possível que você adicione e edite todos os colaboradores que podem ter acesso ao sistema de atendimento. Configurando suas permissões conforme a sua necessidade.`,
        img: usuarios,
      },
      {
        id: 12,
        title: 'API',
        description: `Aqui você vê instruções detalhadas de como utilizar a nossa API para enviar mensagens pelo WhatsApp a partir de softwares desenvolvidos pela sua empresa ou de terceiros.`,
        img: api,
      },
      {
        id: 13,
        title: 'Faturas',
        description: `É por aqui que você acompanha e faz o pagamento do seu plano conosco, tudo de forma bem simplificada.`,
        img: faturas,
      },
      {
        id: 14,
        title: 'Configurações',
        description: `É nesta aba que você edita alguns parâmetros mais específicos do seu ChatPro para atender melhor às suas necessidades.`,
        img: configuracoes,
      },
    ],
  },
  {
    id: 2,
    product: 'EssenSys',
    description:
      'Sistema para Food Service completo. Robô para WhatsApp, Cardápio digital (delivery, mesa, comanda, balcão e garçom), Pix automatizado, ferramentas de marketing e gestão - tudo integrado.',
    link: 'https://essensys.app.br/',
  },
];
