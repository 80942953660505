import React from 'react';
import { Link as ScrollLink } from 'react-scroll'; // Importa Link para rolagem suave
import { Link } from 'react-router-dom'; // Navegação entre páginas
import { FaArrowUp } from 'react-icons/fa'
import Logo from '../../assets/logo.png';

import {
  Container,
  Content,
  LogoContainer,
  LinksContainer,
  RightsContainer,
  ContentRights
} from './styles';

export function Footer() {
  return (
    <Container>
      <Content>
        <LogoContainer>
          <img src={Logo} alt="logo" />
          <span>Simples e confiável.</span>
        </LogoContainer>
        <LinksContainer>
          <ul>
            <li>
              <ScrollLink to="home" smooth={true} duration={500}>
                Início
              </ScrollLink>
            </li>
            <li>
              <ScrollLink to="services" smooth={true} duration={500}>
                Serviços
              </ScrollLink>
            </li>
            <li>
              <ScrollLink to="about" smooth={true} duration={500}>
                Sobre
              </ScrollLink>
            </li>
            <li>
              <ScrollLink to="products" smooth={true} duration={500}>
                Produtos
              </ScrollLink>
            </li>
            <li>
              <ScrollLink to="faq" smooth={true} duration={500}>
                Ajuda
              </ScrollLink>
            </li>
          </ul>
        </LinksContainer>
      </Content>
      <RightsContainer>
         <ContentRights>
          <div>
            <p>© 2024 Buddy Software Solutions LTDA | CNPJ: 51.784.992/0001-26</p>
          </div>
          <div>
            <p>
              <Link to="/terms-of-use">Termos de Uso</Link> | <Link to="/privacy-policy">Política de Privacidade</Link>
            </p>
          </div>
        </ContentRights>
      </RightsContainer>
    </Container>
  );
}
