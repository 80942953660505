import React from 'react';

import NotFound404 from '../../assets/404.svg';

import { Container, TextContainer } from './styles';

export function Page404() {
  return (
    <Container>
      <TextContainer>
        <h1>- 404</h1>
        <span>Page not found!</span>
      </TextContainer>
      <img src={NotFound404} alt="NotFound404" />
    </Container>
  );
}
