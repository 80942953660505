import React, { useState } from 'react';

import { IoIosArrowDown } from "react-icons/io";

import { Container, LeftSide, RightSide, Title, Description } from './styles';

interface ServiceContainerProps {
  title: string;
  description: string;
  img: string;
}

export function ServiceContainer({
  title,
  description,
  img,
}: ServiceContainerProps) {
  const [hover, setHover] = useState(false);

  return (
    <Container
      hover={hover}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      initial={{ translateY: 100, opacity: 0 }}
      whileInView={{ translateY: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{
        duration: 0.3
      }}
    >
      <LeftSide hover={hover}>
        <Title>{title}</Title>
        <IoIosArrowDown />
        <Description hover={hover}>{description}</Description>
      </LeftSide>
      <RightSide>
        <img src={img} alt="img" />
      </RightSide>
    </Container>
  );
}
