import styled from 'styled-components';

interface HeaderProps {
  scrolling?: boolean;
  opened?: boolean;
}

export const Container = styled.div<HeaderProps>`
  position: fixed;
  width: 100%;
  z-index: 999;
  background: var(--dark);

  transition: box-shadow 0.5s ease;

  box-shadow: ${({ scrolling }) => scrolling !== undefined && scrolling && '0px 0px 5px 0px rgba(1, 1, 1, 0.5)'};
`;

export const Content = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 1400px;
  align-items: center;
  justify-content: space-between;

  .openIcon {
    width: 2.5rem;
    height: 2.5rem;

    @media screen and (min-width: 728px) {
      display: none;
    }
  }
`;

export const Logo = styled.div<HeaderProps>`
  display: flex;
  overflow: hidden;

  transition: width 0.5s ease;
  width: ${({ scrolling }) => (scrolling !== undefined && scrolling ? '2.8rem' : '14rem')};

  &:hover {
    width: 14rem;
  }

  img {
    margin-left: -1.4rem;
    height: 5rem;
    cursor: pointer;
  }
`;

export const Nav = styled.nav`
  display: flex;

  @media screen and (max-width: 728px) {
    display: none;
  }

  ul {
    display: flex;
    list-style: none;

    li {
      padding: 0.5rem;
      cursor: pointer;
      font-weight: bold;

      &:hover {
        filter: brightness(0.9);
      }

      a.active {
        filter: brightness(1.5);
        color: var(--primary);
      }
    }
  }
`;

export const NavMobile = styled.nav<HeaderProps>`
  overflow: hidden;
  width: 100%;
  height: ${({ opened }) => opened !== undefined && opened ? '100vh' : '0rem' };
  padding-bottom: ${({ opened }) => opened !== undefined && opened ? '8rem' : '0rem' };

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ul {
    width: 100%;

    li {
      list-style: none;

      a {
        font-size: 2rem;
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 1.5rem 0;
        font-weight: bold;
      }

      a.active {
        color: var(--primary);
      }

      svg {
        display: flex;
        width: 100%;
        font-size: 5rem;
      }
    }
  }
`;
