import React from 'react';

import {
  Container,
  Content,
  Title,
  Description,
  Background
} from './styles'

import { AboutData } from '../../../utils/texts';

export function About() {
  return (
    <Container id='about'>
      <Background />
      <Content>
        <Title>{AboutData.title}</Title>
        <Description>{AboutData.description}</Description>
      </Content>
    </Container>
  );
}