import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';

import { MdOutlineMenu, MdOutlineClose  } from "react-icons/md";
import { IoIosArrowUp } from "react-icons/io";

import LogoImg from '../../assets/logo.png';

import { Container, Content, Logo, Nav, NavMobile } from './styles';

export function Header() {
  const [scrolling, setScrolling] = useState(false);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container scrolling={scrolling}>
      <Content>
        <Logo scrolling={scrolling}>
          <Link smooth spy to="home">
            <img src={LogoImg} alt="logo" />
          </Link>
        </Logo>
        <Nav>
          <ul>
            <li>
              <Link smooth spy to="home" activeClass="active">
                Início
              </Link>
            </li>
            <li>
              <Link smooth spy to="services" activeClass="active">
                Serviços
              </Link>
            </li>
            <li>
              <Link smooth spy to="about" activeClass="active">
                Sobre
              </Link>
            </li>
            <li>
              <Link smooth spy to="products" activeClass="active">
                Produtos
              </Link>
            </li>
            <li>
              <Link smooth spy to="help" activeClass="active">
                Ajuda
              </Link>
            </li>
          </ul>
        </Nav>
        {
          opened ? <MdOutlineClose className='openIcon' onClick={() => { setOpened(!opened); }}/> : 
          <MdOutlineMenu className='openIcon' onClick={() => { setOpened(!opened); }}/>
        }
      </Content>
        <NavMobile opened={opened}>
          <ul>
            <li>
              <Link smooth spy to="home" activeClass="active" onClick={() => { setOpened(!opened); }}>
                Início
              </Link>
            </li>
            <li>
              <Link smooth spy to="services" activeClass="active" onClick={() => { setOpened(!opened); }}>
                Serviços
              </Link>
            </li>
            <li>
              <Link smooth spy to="about" activeClass="active" onClick={() => { setOpened(!opened); }}>
                Sobre
              </Link>
            </li>
            <li>
              <Link smooth spy to="products" activeClass="active" onClick={() => { setOpened(!opened); }}>
                Produtos
              </Link>
            </li>
            <li>
              <Link smooth spy to="help" activeClass="active" onClick={() => { setOpened(!opened); }}>
                Ajuda
              </Link>
            </li>
            <li>
              <IoIosArrowUp onClick={() => { setOpened(!opened); }}/>
            </li>
          </ul>
        </NavMobile>
    </Container>
  );
}
