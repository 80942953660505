import React from 'react';
import { FiArrowDownRight } from 'react-icons/fi';

import Home01 from '../../../assets/home01.svg';

import { Container, Content, LeftSide, RightSide } from './styles';
import { Button } from '../../Button';
import { Link } from 'react-scroll';

import { HomeData } from '../../../utils/texts';

export const Home: React.FC = () => {
  const titleWithSpan = (
    <h1 dangerouslySetInnerHTML={{ __html: HomeData.title }} />
  );
  const descriptionWithSpan = (
    <span dangerouslySetInnerHTML={{ __html: HomeData.description }} />
  );

  return (
    <Container id='home'>
      <Content
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      >
        <LeftSide>
          {titleWithSpan}
          {descriptionWithSpan}
          <Link smooth spy to="help">
            <Button icon={<FiArrowDownRight />} site='Buddy'>Contato</Button>
          </Link>
        </LeftSide>

        <RightSide>
          <img src={Home01} alt="people" className='people' />
        </RightSide>
      </Content>
    </Container>
  );
};
