import styled from 'styled-components';
import { FaArrowUp } from 'react-icons/fa';

export const Container = styled.div`
  background: var(--darkLight);
`;

export const Content = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1400px;
  height: 16.3rem;
  padding: 0 2rem;

  @media screen and (max-width: 728px) {
    flex-direction: column;
    height: fit-content;
    padding-bottom: 1rem;
    text-align: center;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 10rem;
  margin-left: -2.2rem;
  margin-top: -3rem;

  span {
    margin-left: -5rem;
  }

  @media screen and (max-width: 728px) {
    margin-left: 0rem;
    width: 100%;
    margin-top: 0;
    margin-bottom: 2rem;

    span {
      margin-left: 0rem;
    }
  }
`;

export const LinksContainer = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 2rem 5rem;

    li {
      font-weight: bold;
      list-style: none;
      cursor: pointer;
    }
  }
`;

export const RightsContainer = styled.div`
  border-top: 1px solid var(--light);
`;

export const ContentRights = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 1400px;
  height: 100%;
  padding: 1rem 2rem 5rem 2rem;
  color: white; // Texto branco

  p {
    margin: 0.5rem 0;
  }

  .scroll-icon {
    margin-top: 1rem;
    cursor: pointer;
  }

  a {
    color: white; // Links brancos
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  
  @media screen and (max-width: 728px) {
    text-align: center;
    padding: 1rem 4rem;
  }
`;
