import styled from 'styled-components';

interface ContainerProps {
  scrolling: boolean;
  site: 'Buddy' | 'SolusChat';
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  margin: 0 auto;
  right: 0;
  left: 0;
  transition: bottom 0.5s ease;
  bottom: ${({ scrolling }) => (scrolling ? '0' : '-10rem')};
  max-width: 1600px;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  z-index: 999;
  pointer-events: none;

  svg {
    pointer-events: all;
    cursor: pointer;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 1));
    color: ${({ site }) =>
      site === 'Buddy'
        ? 'var(--primary)'
        : site === 'SolusChat'
          ? 'var(--primarySolusChat)'
          : ''};
    filter: ${({ site }) =>
      site === 'Buddy'
        ? 'brightness(1.2)'
        : site === 'SolusChat'
          ? 'brightness(1)'
          : ''};
  }
`;
