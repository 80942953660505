import styled from 'styled-components';

export const Background = styled.div`
  background: var(--darkLight);
`;

export const Container = styled.section`
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 2rem;
  display: flex;
  min-height: 100vh;
  padding: 8rem 2rem;
  flex-direction: column;
`;

export const Title = styled.h1`
  text-align: center;
  margin-bottom: 1rem;
`;

export const SubTitle = styled.span`
  text-align: center;
`;

export const ServicesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 3rem 0;
`;
