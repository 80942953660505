import Development from '../assets/development.svg';
import Consulting from '../assets/consulting.svg';
import Uiux from '../assets/uiux.svg';
import Maintenance from '../assets/maintenance.svg';
import Experience from '../assets/experience.svg';

interface ServicesProps {
  id: number;
  title: string;
  description: string;
  img: string;
}

export const services: ServicesProps[] = [
  {
    id: 0,
    title: 'Desenvolvimento de Software Personalizado',
    description:
      'Criamos soluções sob medida, desde aplicativos móveis e web até sistemas corporativos complexos, adaptados para atender aos requisitos exclusivos do seu negócio.',
    img: Development,
  },
  {
    id: 1,
    title: 'Consultoria Tecnológica',
    description:
      'Conte com a nossa experiência para orientá-lo na escolha das melhores tecnologias e práticas para impulsionar sua empresa e ganhar vantagem competitiva.',
    img: Consulting,
  },
  {
    id: 2,
    title: 'Design de Interface e Experiência do Usuário (UI/UX)',
    description:
      'Garantimos que seu software seja não apenas funcional, mas também agradável e intuitivo, proporcionando a melhor experiência para seus usuários.',
    img: Uiux,
  },
  {
    id: 3,
    title: 'Manutenção e Suporte',
    description:
      'Estamos ao seu lado mesmo após o lançamento do projeto, fornecendo suporte contínuo e atualizações para garantir que sua solução funcione sempre com desempenho máximo.Estamos ao seu lado mesmo após o lançamento do projeto, fornecendo suporte contínuo e atualizações para garantir que sua solução funcione sempre com desempenho máximo.',
    img: Maintenance,
  },
  {
    id: 4,
    title: 'Experiência em Integrações',
    description:
      'Somos especialistas em integrar sistemas e ferramentas, aprimorando processos para impulsionar a eficiência operacional de sua empresa, seja na integração de plataformas de pagamento, sistemas de gerenciamento de conteúdo ou outras necessidades específicas.',
    img: Experience,
  },
];
