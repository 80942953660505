import React from 'react';

import { ServiceContainer } from '../../ServiceContainer';
import { services } from '../../../utils/services';

import { Background, Container, Title, SubTitle, ServicesGrid } from './styles';

import { ServicesData } from '../../../utils/texts';

export function Services() {
  return (
    <Background id='services'>
      <Container>
        <Title>{ServicesData.title}</Title>
        <SubTitle>{ServicesData.description}</SubTitle>
        <ServicesGrid>
          {services.map(service =>
            <ServiceContainer
              key={service.id}
              title={service.title}
              description={service.description}
              img={service.img}
            />
          )}

        </ServicesGrid>
      </Container>
    </Background>
  );
}
