import React from 'react';

import {
  Container,
  ImgContainer,
  Title,
  Description
} from './styles'

interface CarouselContainerProps {
  title: string;
  description: string;
  img: string;
  zoomIn: () => void;
}

export function CarouselContainer({ title, description, img, zoomIn }:CarouselContainerProps){
  return (
    <Container>
      <ImgContainer onClick={zoomIn}><img src={img} alt={title} /></ImgContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
}